import './App.css';
import {createMuiTheme, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import {Login} from "./pages/Login";
import {ControlFoto} from "./pages/control/ControlFoto";
import {ControlMessaggi} from "./pages/control/ControlMessaggi";
import {ControlWordcloud} from "./pages/control/ControlWordcloud";
import {ControlQuiz} from "./pages/control/ControlQuiz";
import beaWorldTheme from "./themes/beaWorldTheme";
import React from "react";
import {Wordcloud} from "./pages/visual/Wordcloud";
import {Messaggi} from "./pages/visual/Messaggi";
import {FotoWall} from "./pages/visual/FotoWall";
import {Sondaggio} from "./pages/visual/Sondaggio";
import {MessaggiOnlyText} from "./pages/visual/MessaggiOnlyText";
import {WordcloudProcessing} from "./pages/visual/WordcloudProcessing";

function App() {
    const theme = createTheme({
        palette: {
            background:{
                default:'#0e0e0e'
            }
        }
    });
  return (
    <div className="App">
        <ThemeProvider theme={beaWorldTheme}>
        <CssBaseline/>
        <Routes>
            <Route path={'/*'} element={<Home/>}/>
            <Route path={'/login'} element={<Login/>}/>
            <Route element={<ProtectedRoute/>}>
                <Route path={'/control/messaggi'} element={<ControlMessaggi/>}/>
                <Route path={'/control/wordcloud'} element={<ControlWordcloud/>}/>
                <Route path={'/control/foto'} element={<ControlFoto/>}/>
                <Route path={'/control/quiz'} element={<ControlQuiz/>}/>
            </Route>
            <Route path={'/visual/foto'} element={<FotoWall/>}/>
            <Route path={'/visual/messaggi'} element={<Messaggi/>}/>
            <Route path={'/visual/messaggi/:dim'} element={<Messaggi/>}/>
            <Route path={'/visual/messaggi/text'} element={<MessaggiOnlyText/>}/>
            <Route path={'/visual/wordcloud'} element={<Wordcloud/>}/>
            <Route path={'/visual/wordcloud/text'} element={<WordcloudProcessing/>}/>
            <Route path={'/visual/sondaggio/:id'} element={<Sondaggio/>}/>
        </Routes>
        </ThemeProvider>
    </div>
  );
}

export default App;
