import React, {useEffect, useState} from "react";
import {Backdrop, Box, Button, CircularProgress, Dialog, Grow, Slide, Typography} from "@mui/material";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from "./SuccessCheck";
import useAxios from "axios-hooks";
import beaWorldTheme from "../../themes/beaWorldTheme";
import {useNavigate} from "react-router-dom";
import CustomHeader from "./CustomHeader";
import {API_URL} from "../../config";

const UserPoll = ({quizLoading, quizPlayingId, quiz}) => {
    const navigate = useNavigate()
    const [chosenOption, setChosenOption] = useState(null)
    const [showCheck, setShowCheck] = useState(false)

    let progress = useMotionValue(90)

    useEffect(() => {
        if(quizPlayingId) {
            setChosenOption(null)
            setShowCheck(false)
        }
    }, [quizPlayingId])

    const [{data: chosenOptionSent, loading: sendingChosenOption, error: errorChosenOption}, sendChosenOption] = useAxios({},
        {manual: true}
    )

    useEffect(() => {
        if(!sendingChosenOption && (chosenOption || errorChosenOption)) {
            setShowCheck(true)
            /*setTimeout(() => {
                setShowCheck(false)
                setChosenOption(null)
            }, 2000)*/
        }
        /*if(sendingChosenOption)
            setShowCheck(false)*/
    }, [sendingChosenOption, chosenOption, errorChosenOption])

    function handleSendChosenOption(chosenOption) {
        sendChosenOption({
            data: {answer: chosenOption},
            url: `${API_URL}quiz/${quizPlayingId}`, // TODO: url
            method: "POST"
        })
            .then((res) => {
                setChosenOption(chosenOption)
                console.log("sent:", res)
            })
            .catch((err) => {
                console.log("err:", err)
                setChosenOption(chosenOption)
            })
    }

    // TODO: prevent going back
    return (
        <Dialog fullScreen open={!!quiz} hideBackdrop={true} disableEscapeKeyDown={true}
                TransitionComponent={Transition}>
            <CustomHeader disableBack/>
            <Backdrop
                sx={{color: '#f1ebe9', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={quizLoading || sendingChosenOption}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grow in={true} exit={true} timeout={600}>
                <Box display={'flex'}
                     flexDirection={'column'}
                     alignItems={"center"}
                     //sx={{position: 'absolute', bottom: '20px', left: 0, right: 0, textAlign: 'center'}}
                >
                    <Box justifyContent={'start'} px={4} mt={3}>
                        <Typography gutterBottom variant={'h2'}>{quiz?.title || ''}</Typography>
                        <Typography gutterBottom variant={'h5'}>{quiz?.domanda || ''}</Typography>
                    </Box>
                    {showCheck &&
                        <Box m={0} width={'60%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <motion.div
                                initial={{x: 0}}
                                animate={{x: 100}}
                                style={{x: progress}}
                                transition={{duration: 0.8}}
                            />
                            <SuccessCheck progress={progress} color={beaWorldTheme.palette.secondary.main}/>
                        </Box>
                    }
                    {<Box width={'85%'} my={showCheck ? 1 : 3}>
                        {
                            (quiz?.risposte || []).map((risposta, i) =>
                                <Button key={i} sx={{
                                    mb: 2.2,
                                    px: 3,
                                    py: 1.3,
                                    fontWeight: 'bold',
                                    borderRadius: '1.8rem',
                                    minHeight: '60px',
                                    background: 'hsl(225deg 48% 44% / 0%)',
                                    border: chosenOption === risposta ? '2px solid ' + beaWorldTheme.palette.primary.main : 'none',
                                    boxShadow: '3px 5px 8px 0px #24388bd1, -2px -1px 8px hsl(226deg 23% 82%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%)'
                                }}
                                        fullWidth
                                        variant={chosenOption !== risposta ? 'standard' : 'contained'}
                                        disabled={!!chosenOption} // TODO
                                        onClick={() => handleSendChosenOption(risposta)}
                                >
                                    {risposta}
                                </Button>
                            )
                        }
                    </Box>}
                </Box>
            </Grow>
        </Dialog>
    )
}

export default UserPoll

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});