import React, {useEffect, useState} from "react";
import visual from "../assets/images/visual_bea.png";
import {Box, Button, Grid, Grow, IconButton, Typography} from "@mui/material";
import CustomButton from "./user/CustomButton";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {UserMessaggi} from "./user/UserMessaggi";
import UserFoto from "./user/UserFoto";
import UserPoll from "./user/UserPoll";
import {CameraEnhance, FilterDrama, Message} from "@mui/icons-material";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import CustomHeader from "./user/CustomHeader";
import _ from 'lodash'
import clap from "../assets/images/clap.png"
import beaWorldTheme from "../themes/beaWorldTheme";
import logoPlesh from "../assets/images/logo_plesh.png";
import PoweredBy from "./user/PoweredBy";
import WrapperInterazione from "./user/WrapperInterazione";

function HomeUI({pulsantiera: pulsantiAttivi}) {
    const navigate = useNavigate()

    return (
        <>
            <header className="App-header">
                <CustomHeader disableBack/>
                <Grow in={true} exit={true} timeout={600}>
                    <Box display={'flex'}>
                        <img src={visual} alt="visual" style={{maxWidth: '350px', width: '68%', margin: 'auto'}}/>
                    </Box>
                </Grow>
            </header>
            <Grow in={true} exit={true} timeout={600}>
                <Grid px={3} my={1} container spacing={2} alignItems={'center'} justifyContent={'center'}
                      sx={{position: 'absolute', bottom: '35px'}}>
                    {/*<Typography variant={'h4'} fontWeight={'bold'}>Invia...</Typography>*/}
                    {
                        pulsantiAttivi.map((pulsante, i) => {
                            if(pulsante.path === 'cta')
                                return <Grow key={i} in={true} exit={true} timeout={600 * (i + 1)}>
                                    <Grid item xs={pulsante.xs}>
                                        <Button key={i} sx={{
                                            mt: 2,
                                            fontWeight: 'bold',
                                            borderRadius: '1.4rem',
                                            maxHeight: '40px',
                                            //background: '#E77535',
                                            color: beaWorldTheme.palette.secondary.main,
                                            background: beaWorldTheme.palette.text.primary,
                                            border: 'none',
                                            boxShadow: '2px 2px 8px 0px #89c9adf2, -2px -1px 8px hsl(226deg 23% 82%), 6px 6px 8px rgb(255 255 255 / 8%), 6px 6px 10px rgb(0 0 0 / 15%)'
                                        }}
                                                fullWidth
                                                variant={'standard'}
                                                onClick={() => navigate(pulsante.path)}
                                        >
                                            {pulsante.label}
                                            {pulsante.icon}
                                        </Button>
                                    </Grid>
                                </Grow>
                            return !pulsante.hidden &&
                                <Grow key={i} in={true} exit={true} timeout={600 * (i + 1)}>
                                    <Grid item xs={pulsante.xs}>
                                        <CustomButton
                                            label={pulsante.label}
                                            onClick={() => navigate(pulsante.path)}
                                            icon={pulsante.icon}
                                        />
                                    </Grid>
                                </Grow>
                            }
                        )
                    }
                </Grid>
            </Grow>
        </>
    );
}

export const pulsantiera = [
    {path: "messaggi", label: "Send a message", subtitle: "The messages sent will be displayed on stage!",
        children: <UserMessaggi/>, icon: <Message fontSize={'large'} color={'secondary'}/>, xs: 12, hidden: false},
    {path: "wordcloud", label: "Wordcloud", title: "In one word, what does Live Free mean to you…?", description: "From the words collected, Artificial Intelligence will generate a representative image of BEA World to be presented at the end of the event.",
        children: <UserMessaggi wordStorm/>, icon: <FilterDrama fontSize={'large'} color={'secondary'}/>, xs: 12, hidden: false},
    {path: "foto", label: "Send a picture", subtitle: "Send your picture and become part of the show!",
        children: <UserFoto/>, icon: <CameraEnhance fontSize={'large'} color={'secondary'}/>, xs: 12, hidden: false},
    {path: "cta", label: "Find out more", description: "Innovative digital services and products to maximize the audience experience in the world of events and live shows", subtitle: "Want to find out more about this app? Leave us your email and you'll be contacted!",
        children: <UserMessaggi cta/>, icon: <img src={clap} width={'30%'} style={{maxWidth: '100px'}}/>, xs: 12, plesh: true, hidden: false},
]

export const Home = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [openSections,] = useDocument(firestore.doc('test/sezioniAperte/'))
    const [pulsantiAttivi, setPulsantiAttivi] = useState(pulsantiera)

    const [quizPlaying, quizIdLoading, quizIdError] = useDocument(firestore.doc('test/quiz/'))
    const [quizData, setQuizData] = useState(null)

    useEffect(() => {
        if(openSections && openSections.data()) {
            const visible = openSections.data()
            if(!visible[location.pathname]) {
                navigate('/')
            }
            setPulsantiAttivi(_.filter(pulsantiera, function (o) {
                return visible[o.path]
            }))
        }
    }, [openSections])

    useEffect(() => {
        if(quizPlaying && quizPlaying.data().show) {
            console.log("quizPlaying:",quizPlaying.data())
            var docRef = firestore.collection(`test/data/quizzes/`).doc(quizPlaying.data().id);
            docRef.get().then((doc) => {
                if (doc.exists) {
                    setQuizData(doc.data())
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });

            //console.log("quizData:",quizData)
            navigate('/'+quizPlaying.data().id)
        } else {
            navigate('/')
        }
    }, [quizPlaying])

    useEffect(() => {
        if(quizPlaying && !quizIdLoading) {
        }
    }, [quizPlaying])

    return (
            <Routes>
                <Route index element={<HomeUI pulsantiera={pulsantiAttivi}/>}/>
                {
                    pulsantiera.map((pulsante, i) =>
                        <Route key={i} path={pulsante.path}
                               element={<WrapperInterazione title={pulsante.title||pulsante.label} subtitle={pulsante?.subtitle} description={pulsante.description}
                                                            children={pulsante.children} plesh={pulsante.plesh}/>}/>
                    )
                }
                {quizPlaying && quizData && <Route path={'/:id'} element={<UserPoll quizLoading={quizIdLoading} quizPlayingId={quizPlaying.data().id} quiz={quizData}/>}/>}
                {/*<Route path={'/:id'} element={<UserPoll quizLoading={quizIdLoading} quiz={quizPlaying?.data()}/>}/>*/}
            </Routes>
    )
}