import {Box, IconButton} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import logoPlesh from "../../assets/images/logo_plesh.png";
import React from "react";
import {useNavigate} from "react-router-dom";
import beaWorldTheme from "../../themes/beaWorldTheme";

const CustomHeader = ({disableBack = false, plesh = true}) => {
    const navigate = useNavigate()
    function handleBack() {
        navigate('/')
    }

    return (<Box mt={0.5} pl={1.5} pr={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box flex={1} display={'flex'} alignItems={'center'} >
            {!disableBack ?
                <IconButton color={'primary'} onClick={handleBack}>
                    <ArrowBack fontSize={'large'}/>
                </IconButton> : null}
                <img src={logo} className="App-logo" alt="logo" style={{maxWidth: '100px', width: '37%', marginLeft: '3%'}} /*onClick={() => {
                    window.open("https://besteventawards.it/", '_blank');}}*//>
        </Box>
        <Box onClick={() => {
            window.open("https://plesh.co/", '_blank');
        }} mt={1} flex={1} display={'flex'} flexDirection={"column"} alignItems={'flex-end'} justifyContent={'baseline'}>
            {plesh && <img src={logoPlesh} className="App-logo" alt="logo"
                  style={{maxWidth: '100px', width: '42%', marginRight: '6%', filter: "brightness(0) invert(1)"}}/>}
        </Box>
    </Box>)
}

export default CustomHeader