import React, {useEffect, useState} from "react";
import ReactWordcloud from "react-wordcloud";
import beaWorldTheme from "../../../themes/beaWorldTheme";
import {Box, Paper} from "@mui/material";
import {COLORI} from "../../../config";

const options = {
    //colors: ['#ffffff', beaWorldTheme.palette.primary.main], //rgba(47,75,155,1)
    colors: ['#E77535', '#9bdabe', '#ffffff'/*beaItaliaTheme.palette.primary.main*/], //rgba(47,75,155,1)
    //colors: COLORI,
    fontFamily: 'Gotham',
    enableTooltip: false,
    deterministic:true,
    fontSizes: [30, 110],
    fontWeight: 'bold',
    padding: 10,
    rotations: 0,
    rotationAngles: 0,
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
    textAttributes: {"class": 'customWord'}
}

export const WordcloudContent = ({result}) => {
    const [parole, setParole] = useState([])

    useEffect(() => {
        Array.isArray(result) && setParole(result.slice(0, 80))
    }, [result])
    return (
        <Box component={Paper} p={2} borderRadius={'3rem'} display={'flex'} alignItems={'center'}
             sx={{
                background: 'transparent',
                //background: beaItaliaTheme.palette.text.primary,
                //boxShadow: `11px 11px 10px 0px ${beaItaliaTheme.palette.primary.main}`
            }}
             maxWidth={'1500px'} maxHeight={'600px'} minHeight={'600px'}>
            <ReactWordcloud options={options} words={parole}/>
        </Box>
    )
}
