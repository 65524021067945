import React from "react";
import {Box, Button, IconButton} from "@mui/material";
import beaWorldTheme from "../../themes/beaWorldTheme";


export default function CustomButton({label, onClick, icon}) {
    return (
        <Button onClick={onClick} fullWidth
                variant={"neumorphic"}
                //startIcon={icon}
            //sx={{color: beaWorldTheme.palette.secondary.main}}
        >
            {icon}
            <Box ml={1.5}>{label}</Box>
        </Button>
    )
}
