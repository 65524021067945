import {createTheme, responsiveFontSizes} from "@mui/material";

let beaWorldTheme = createTheme({
    typography: {
        fontFamily: ['Gotham']
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: 'neumorphic'},
                    style: {
                        height: '60px',
                        width:'100%',
                        fontSize: '18px',
                        fontWeight: 'bolder',
                        border:'none',
                        borderRadius: '1.8rem',
                        borderWidth: '3px',
                        background: '#89C9AD',
                        boxShadow: '0px 0px 6px 0px rgb(137 201 173 / 73%)',
                        "&:active": {
                            boxShadow: 'inset -2px -2px 6px rgb(137 201 173 / 65%), inset -2px -2px 4px rgb(255 255 255 / 65%), inset 2px 2px 2px rgb(255 255 255 / 8%), inset 2px 2px 15px rgb(47 75 155 / 66%)'
                        },
                        "&:disabled": {
                            background: 'rgba(137,201,173,0.15)',
                            boxShadow: 'inset -2px -1px 7px 0px rgb(137 201 173 / 35%)'
                            //background: 'linear-gradient(90deg, rgba(242,137,80,0.5) 0%, rgba(47,76,156,0.5))',
                        }
                    }
                }
            ]
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#354aa2'
                }
            }
        }
    },
    palette: {
        mode:'dark',
        primary: {
            main:'#89C9AD',
        },
        secondary: {
            main: '#354aa2',
        },
        text: {
            primary: '#eceff1',
        },
        background:{
            default: '#24388B'
        }
    }
})

beaWorldTheme = responsiveFontSizes(beaWorldTheme)

export default beaWorldTheme
