import React, {useState} from "react";
import {ControlLayout} from "../../layouts/ControlLayout";
import useAxios from "axios-hooks";
import {
    Box,
    Button,
    Chip,
    CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup, Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {Delete, Refresh, Send} from "@mui/icons-material";
import {API_URL} from "../../config";
import axios from "axios";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import {Autocomplete} from "@mui/lab";

export const ControlWordcloud = () => {
    const [active, activeLoading, activeError] = useDocument(firestore.doc('test/sezioniAperte'))
    const [parola, setParola] = useState('')
    const [amount, setAmount] = useState(1)
    const [{data, loading, error}, refetch] = useAxios(`${API_URL}wordcloud`)
    const [success, setSuccess] = useState(false)
    const [open, setOpen] = useState(false)

    const inviaParola = async () => {

        await axios.post(`${API_URL}wordcloud/moderate`, {message: parola, count: amount})
            .finally(() => {
                setSuccess(true)
                setParola('')
                setAmount(1)
                refetch()
            })
    }

    const handleActive = async () => {
        await axios.post(`${API_URL}actions`, {action: 'wordcloud', value: !active.data().wordcloud})
            .finally(() => refetch())
    }

    const reset = async () => {
        await axios.post(`${API_URL}clear/wordcloud`,{})
            .then(() => setOpen(!open))
            .finally(() => refetch())
    }

    return (
        <ControlLayout>
            <Dialog open={open} onClose={() => setOpen(!open)}>
                <DialogContent sx={{backgroundColor:'#121212'}}>
                    <DialogTitle>
                        Vuoi eliminare tutti i risultati della wordcloud?
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setOpen(!open)} variant={"outlined"}>no</Button>
                        <Button onClick={reset} variant={"contained"} color={"error"}>Si</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Typography variant={'h4'} fontWeight={"bold"}>Wordcloud</Typography>
            <Divider sx={{my: 2}}/>
            <Box sx={{pb: 2}}>
                <Typography variant={"h6"}>
                    Attiva/disattiva sezione
                </Typography>
                {activeLoading ? <CircularProgress/>
                    :
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={active.data().wordcloud}
                                                           onChange={(e) => handleActive(e.target.checked)}/>}
                                          label={active.data().wordcloud ? 'Wordcloud APERTA' : "Wordcloud CHIUSA"}/>
                    </FormGroup>}
            </Box>
            <Divider/>
            <Typography variant={"h6"} sx={{pt: 2}}>
                Moderazione
            </Typography>
            <Stack sx={{py: 1}} direction={"row"} justifyContent={"space-between"}>
                <Button onClick={() => refetch()} variant={"contained"} startIcon={<Refresh/>}>Ricarica</Button>
                {<Autocomplete
                    size={'small'}
                    sx={{width: '60%'}}
                    options={data||[]}
                    onChange={(event, newValue) => {
                        setParola(newValue.text);
                    }}
                    renderInput={(params) => <TextField {...params} size={'small'} label="Parole, parole, parole" />}
                    getOptionLabel={(option) => `${option.text} (${option.value})`}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Box mb={0.5}>
                                {option.text} <Chip label={option.value} size={'small'}/>
                            </Box>
                        </li>
                    )}
                />}
                <Button onClick={() => setOpen(!open)} variant={"outlined"} color={"error"} startIcon={<Delete/>}>Reset risultati</Button>
            </Stack>
            <Stack direction={'row'} spacing={3} alignItems={"baseline"}>
                <TextField variant={"standard"} value={parola} label={'Parola'}
                           onChange={(e) => setParola(e.target.value)}/>
                <TextField variant={"standard"} value={amount} label={'Ricorrenza'} type={"number"}
                           onChange={(e) => setAmount(e.target.value)}/>
                <Box>
                    <Button variant={"contained"} disabled={parola==='' || parola.includes(' ')} endIcon={<Send/>} onClick={inviaParola}>
                        Invia
                    </Button>
                </Box>
                {success && <Typography color={'green'}>
                    Inviata
                </Typography>}
            </Stack>
            <Box sx={{py: 2}}>
                {loading ? <CircularProgress/>
                    :<>
                    <Box mt={1.5}>
                        {data.map((d, i) =>
                            <Box mb={0.5} key={i}>
                                {i}) {d.text} <Chip label={d.value} size={'small'} color={i <= 40 ? 'success' : 'warning'}/>
                            </Box>
                        )}
                    </Box>
                    </>
                }
            </Box>
        </ControlLayout>
    )
}
