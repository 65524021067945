import React from "react";
import {Box} from "@mui/material";

export const WordcloudProcessing = () => {
    return(
        <div>
            <iframe src="https://openprocessing.org/sketch/1751992/embed/?plusEmbedHash=YzI2MjhmNTAwNWRmZTBlYzE2MTUwNzQyZmZmZWRjYjNkMjlkMDZkODZkOTA5NmRkYTZiM2JlNTFkYjI5NTRlNDQ5MjM5NjIxMDY5NTYzNjEyZjI1Yjc3YTgyMTE0NjE0MjRmNmE1YmYzMWI3NTM4Mjg1NjljNWI5NTUzMDkyMDFFaVJJU0tyZ3FkeU5lb3dhdHF3eEViby8yekNUSmVZRDdpdG1qdjZpT3E0TU1qczMrNzdIK3FFWkNQQnJLMVo4TDhFeVdWcFJlS0ZtUDZ1akhXYzBYQT09&plusEmbedFullscreen=true" width="100%" style={{border:'none', height:'100vh'}}></iframe>
        </div>
    )
}
