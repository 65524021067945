import React, {useEffect} from "react";
import {Box, CircularProgress, Container, Stack} from "@mui/material";
import {API_URL, COLORI} from "../../config";
import useAxios from "axios-hooks";
import {useParams} from "react-router-dom";
import {SondaggioContent} from "../../components/visual/content/SondaggioContent";

export const Sondaggio = () => {
    const {id} = useParams()
    const [{data: results, loading, error}, refetch] = useAxios(
        `${API_URL}quiz/${id}`
    )

    useEffect(() => {
        refetch()
        window.clearInterval()
        let intervalId = window.setInterval(() => refetch(),4000)
        return ()=> {
            window.clearInterval(intervalId)
        }
    }, [])

    return (
        <Container maxWidth={'xl'} sx={{height: '100vh', background: 'rgba(20,37,84,0.5)'}}>
            {(results) && <SondaggioContent r={results}/>}
        </Container>
    )
}
