export const API_URL = "https://api-bea.plesh.co/"
//export const API_URL = "https://dev-api-davide.plesh.co/"

export const COLORI = ['#9bdabe', '#ffffff', '#667ec4', '#f5884a']

export const MAX_PHOTOS = 81

export const MAX_CHAR = 150;

export const PERCENTAGE_COLOR = '#000'
