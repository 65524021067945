import React from "react";
import {Box, Grow, Typography} from "@mui/material";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../../firebase/clientApp";
import beaWorldTheme from "../../themes/beaWorldTheme";
import {useParams} from "react-router-dom";

export const Messaggi = () => {
    const {dim} = useParams()
    const [messagePlaying, messageLoading, messageError] = useDocument(firestore.doc('test/message/'))

    console.log("messagePlaying:",messagePlaying?.data()?.message)
    return(
        messagePlaying?.data()?.message ?
            dim ? <Grow in={true} exit={true} timeout={900}>
                <div>
                    <div className={"triangoloEq"}></div>
                    <Box py={9} px={5} borderRadius={'2rem'}
                         sx={{
                             background: beaWorldTheme.palette.text.primary,
                             boxShadow: `11px 11px 10px 0px ${beaWorldTheme.palette.primary.main}`
                         }} maxWidth={`${dim}px`} maxHeight={'690px'}>
                        <Typography variant={'h2'} color={beaWorldTheme.palette.secondary.main}
                                    fontWeight={'bold'}
                                    textAlign={'center'}>
                            {messagePlaying?.data()?.message}
                        </Typography>
                    </Box>
                </div>
            </Grow>
            : <Grow in={true} exit={true} timeout={900}>
                <div>
                    <div className={"triangoloEq"}></div>
                    <Box py={5} px={5} borderRadius={'2rem'}
                         sx={{
                             background: beaWorldTheme.palette.text.primary,
                             boxShadow: `11px 11px 10px 0px ${beaWorldTheme.palette.primary.main}`
                         }} maxWidth={'650px'} maxHeight={'690px'}>
                        <Typography variant={'h2'} color={beaWorldTheme.palette.secondary.main}
                                    fontWeight={'bold'}
                                    textAlign={'center'}>
                            {messagePlaying?.data()?.message}
                        </Typography>
                    </Box>
                </div>
            </Grow>
            : null
  )
}
